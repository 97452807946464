@import "../../styles/colors.scss";

.tab-nav {
  .tab-nav-group {
    display: flex;
    .tab-nav-button {
      display: flex;
      justify-content: center;

      &.ant-radio-button-wrapper-checked {
        background-color: $brand-1;
        border-color: $brand-1;
      }

      &:not(.ant-radio-button-wrapper-checked) {
        background-color: $gray-5;
        color: white;
      }
    }
  }
}
