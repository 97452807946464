@import "../../styles/colors.scss";

.new-lottery-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: calc(100% - 24px);
  min-height: 170px;

  margin: 12px;
  border: 1px solid #e7e7e7;
  border-radius: 2px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));

  cursor: pointer;

  .new-lottery-icon {
    font-size: 28px;
    color: $brand-1;
  }

  .new-match-text {
    margin-top: 10px;
  }
}
