@import "../../styles/colors.scss";

.lottery-tile {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 12px;
  border: 1px solid #e7e7e7;
  border-radius: 2px;
  padding-top: 20px;
  padding-bottom: 24px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));

  &:hover {
    .lottery-menu {
      visibility: visible;
    }
  }

  .lottery-menu {
    visibility: hidden;
    position: absolute;
    top: 6px;
    right: 12px;
    height: 12px;
    color: $brand-1;
  }

  .lottery-date {
    margin-top: 4px;
  }

  .lottery-status {
    margin-top: 4px;
    font-size: 12px;
  }

  .lottery-button {
    margin-top: 4px;
  }
}
