.results-pie-chart {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .responsive-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .breakdown-by-category {
    margin-top: 40px;
    font-weight: 600;
  }
}
