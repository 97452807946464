@import "../../../../styles/colors.scss";

.draggable-attribute {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border: 1px solid $gray-5;
  background-color: white;
  margin: 16px 0;

  &.is-dragging,
  &.is-empty {
    background-color: $gray-2;
    border: 1px dashed $gray-3;
    * {
      visibility: hidden !important;
    }
  }

  .attribute-text {
    margin-left: 8px;
  }

  .order-dropdown {
    min-width: 160px;
    text-align: left;
  }
}
