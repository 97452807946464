@import "../../../../styles/colors.scss";

.custom-grid-header {
  display: flex;

  .custom-header-text {
    display: flex;
    flex-direction: column;

    .custom-header-column-type {
      color: $gray-4;
      font-size: 10px;
      font-weight: 600;
    }
  }

  .custom-header-sort {
    margin-left: 5px;

    svg {
      color: $gray-4;
    }
  }
}
