@import "../../../../styles/colors.scss";

.avela-file-drop {
  width: 100%;
  height: 480px;
  border: 1px solid $gray-3;

  .file-drop-target {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .file-browse-button {
    height: 48px;
    width: 50%;
    margin-top: 24px;
    background-color: $gray-1;
    color: $brand-1;
    border-color: $brand-1;
  }
}
