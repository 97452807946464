.match-view {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .trial-nav {
    display: flex;

    * {
      flex-grow: 1;
      text-align: center;
    }
  }
}
