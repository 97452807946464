@import "../../styles/colors.scss";

.bottom-buttons {
  display: flex;
  justify-content: space-between;
  margin: 38px 38px 28px 38px;

  button {
    display: flex;

    .anticon {
      color: $brand-1;
      font-size: 21px;
    }
  }
}
