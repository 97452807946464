.terms-of-service {
  display: flex;
  flex-direction: column;
  align-items: center;

  .tos-container {
    width: 80%;
    margin: auto;

    * {
      height: 70vh;
      width: 100%;
    }
  }

  .accept-tos-section {
    margin-top: 40px;
  }

  .accept-tos-button {
    margin-top: 24px;
    height: 64px;
    width: 560px;
  }

  .cancel-tos {
    margin-top: 24px;
    margin-bottom: 60px;
  }
}
