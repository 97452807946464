@import "../../styles/colors.scss";

.new-lottery-drawer {
  text-align: center;

  .new-lottery-form {
    margin: 60px 36px;

    h6 {
      margin-bottom: 40px;
    }

    .match-names-header {
      margin: 40px 0;
    }

    .new-lottery-names-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .new-lottery-connector {
      margin: 4px 6px;
    }

    .new-lottery-submit {
      width: 200px;
      height: 48px;
      margin-top: 16px;
      background-color: $brand-1;
      border-color: $brand-1;
    }

    .new-lottery-cancel {
      display: flex;
      justify-content: center;
      margin-top: 16px;

      .cancel-button {
        color: $gray-4;
        text-decoration: underline;
      }
    }
  }
}
