.match-results {
  .results-graphs {
    display: flex;
    justify-content: center;
    text-align: center;

    h6 {
      margin-bottom: 40px;
    }
  }

  .match-results-tab-nav {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;

    .match-results-section-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      line-height: 0;
    }
  }

  .results-icon {
    font-size: 20px;
  }
  .result-buttons {
    float: right;
    margin-top: 20px;

    button {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}
