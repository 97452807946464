@import "./colors.scss";

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  h1 {
    font-size: 56px;
    line-height: 64px;
  }

  h2 {
    font-size: 46px;
    line-height: 54px;
  }

  h3 {
    font-size: 38px;
    line-height: 46px;
  }

  h4 {
    font-size: 30px;
    line-height: 38px;
  }

  h5 {
    font-size: 24px;
    line-height: 32px;
  }

  h6 {
    font-size: 20px;
    line-height: 28px;
  }

  .note-text {
    font-size: 14px;
    line-height: 24px;
  }

  .medium-text {
    font-weight: 600;
  }

  .bold-text {
    font-weight: 700;
  }
}

// antd overrides
body #root {
  .ant-layout {
    background-color: white;
  }

  .ant-select-dropdown {
    a {
      color: black;
    }
  }

  .ant-switch {
    background-color: $brand-1;
  }

  .ant-menu {
    &.ant-menu-horizontal,
    &.ant-menu-inline {
      .ant-menu-item {
        &.ant-menu-item-active,
        &.ant-menu-item-selected {
          &,
          &:after,
          a {
            color: $brand-1;
            border-color: $brand-1;
          }
        }
      }
    }
  }
}
