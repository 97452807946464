.results-bar-charts {
  font-size: 14px;

  .total-counts-row {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin: 20px 0 10px 50px;

    .total-count {
      display: inline-block;
      margin-left: 30px;
    }
  }

  .chart {
    text-transform: capitalize;
  }
}

.recharts-wrapper {
  .recharts-cartesian-axis.recharts-xAxis.xAxis {
    .recharts-text.recharts-label {
      tspan {
        font-size: 11px;
      }
    }

    .recharts-cartesian-axis-ticks {
      tspan {
        font-weight: 600;
      }
    }
  }
}
