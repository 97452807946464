@import "../../styles/colors.scss";

.configuration-page {
  display: block;
  text-align: center;

  .configuration-wrapper {
    display: flex;
    flex-direction: column;

    .section {
      background-color: $gray-1;
      padding: 32px 60px;
      margin: 10px 53px;

      .toggle-row {
        display: flex;
        justify-content: space-between;

        .toggle-text {
          width: 40%;
          margin: 0 16px;

          &:first-child {
            text-align: right;
          }

          &:last-child {
            text-align: left;
          }

          &.selected {
            font-weight: bold;
          }
        }
      }
    }

    .configuration-submit {
      align-self: flex-end;
      margin-right: 53px;
    }
  }
}
