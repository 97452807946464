@import "../../../styles/colors.scss";

.tab-nav-dropdown {
  .dropdown-button-appear {
    max-width: 36px;
  }

  .dropdown-button-appear.dropdown-button-appear-active {
    max-width: 300px;
    transition: max-width 0.5s ease-in;
  }

  .tab-nav-button {
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: white;
    font-size: 12px;
    background-color: $brand-1;
    border: none;
  }
}

.tab-nav-menu {
  font-size: 12px;
  background-color: $brand-1;

  .tab-nav-menu-item {
    font-size: 12px;
  }

  .ant-dropdown-menu-item {
    &,
    a {
      color: white;
      background-color: $brand-1;
    }

    &.ant-dropdown-menu-item-active {
      background-color: $brand-1;
    }
  }
}
