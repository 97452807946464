.layout-sider {
  z-index: 100;

  &,
  & .ant-menu,
  & .ant-layout-sider-trigger {
    background-color: #f3f5f8;
  }

  .ant-layout-sider-trigger {
    border-top: 1px solid #e7e7e7;
  }

  .ant-menu {
    .ant-menu-item {
      margin: 0;
    }

    .ant-menu-item-selected {
      background-color: white;
      box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
}

.ant-layout-content {
  overflow-y: auto;
}
