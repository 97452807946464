@import "../../../../styles/colors.scss";

.tiebreakers {
  margin-top: 50px;

  .left-panel {
    margin-right: 40px;
  }

  .right-panel {
    height: 300px;
    padding: 18px 40px;
    background-color: white;
    border: 1px solid $gray-5;

    &:after {
      content: "Priority Order";
      position: absolute;
      line-height: 24px;
      top: -24px;
      right: 0;
      background-color: $gray-5;
      color: white;
      padding: 0 20px;
    }
  }

  .disabled-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($gray-1, 0.7);
  }
}
