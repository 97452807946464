@import "../../styles/colors.scss";

.avela-button {
  min-width: 120px;
  padding: 8px 36px;
  height: 40px;
  cursor: pointer;

  &.ant-btn-primary:not(:disabled) {
    background-color: $brand-1;
    border-color: $brand-1;
  }

  &.ant-btn-outlined:not(:disabled) {
    color: $brand-1;
    border-color: $brand-1;
  }
}
