.nav-menu {
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.15);

  .nav-top {
    text-align: center;
    padding: 16px 0;
    border-bottom: 1px solid #e7e7e7;
  }

  .nav-center {
    flex-grow: 2;
    overflow-y: auto;

    .lottery-name {
      margin: 18px 24px 2px 24px;
      font-weight: 600;
    }

    .ant-menu-item {
      height: 48px;

      &.ant-menu-item-selected {
        .trial-options {
          visibility: visible;
        }
      }
    }

    .new-trial {
      margin: 0 25px;

      .anticon {
        color: #5d1ca8;
      }
    }
  }

  .nav-bottom {
    border-top: 1px solid #e7e7e7;
    padding-top: 8px;
    padding-bottom: 16px;
  }
}

// antd overrides
.ant-select-dropdown {
  a {
    color: black;
  }
}
