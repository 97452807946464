.data-nav {
  width: 100%;
  display: flex;
  justify-content: center;

  .data-nav-button {
    height: 32px;
    width: 160px;
  }
}
