@import "../../../../styles/colors.scss";

.map-columns {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 45px;

  .mapping-section {
    width: 100%;
    margin-top: 40px;

    .mapping-header {
      color: $gray-4;
    }

    .mapping-rows-container {
      margin-top: 20px;

      .mapping-row {
        height: 64px;
        margin-top: 8px;

        .mapping-form-item {
          display: flex;
          flex-grow: 2;
          margin: 0;

          .mapping-select {
            min-width: 180px;

            .option-example {
              color: $gray-4;
            }

            .ant-select-selector {
              height: 40px;

              .ant-select-selection-item {
                line-height: 40px;
              }
            }
          }
        }
      }
    }
  }
}

.map-columns-submit {
  align-self: flex-end;
  margin-top: 20px;
}

.data-mapping-select-dropdown {
  .option-example {
    color: $gray-4;
  }

  .ant-select-item {
    height: 50px;
    padding: 0;
    padding: 20px 12px 0 12px;
    border-bottom: 1px solid $gray-2;
  }
}
