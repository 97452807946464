.trial {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;

  .trial-name {
    flex-grow: 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 8px;
    width: 100%;
  }

  .trial-index {
    color: #5d1ca8;
    margin-right: 8px;
  }
}

.trial-options {
  position: absolute;
  right: 0;
  top: 5px;
  visibility: hidden;
}
